import { css, CSSObject, InterpolationWithTheme } from "@emotion/core";
import {
  bpMedium,
  colorGoldStroke,
  colorGrayStroke
} from "../../stylingConstants";
import React from "react";
import { horizontalMargin } from "../../margins";
import { ContentfulFluid } from "../../../graphql-schema-types";
import Image, { FixedObject, FluidObject } from "gatsby-image";
import { captionTypeface } from "./Text";

type Props = {
  tint?: "gold" | "gray";
  image: Partial<ContentfulFluid>;
  name?: string | null;
};

const imageStyle = css`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  @media ${bpMedium} {
    height: 92px;
    width: 92px;
  }
`;

const outlineStyle = css`
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 80px;
  width: 80px;
  padding: 4px;
  ${horizontalMargin("auto")}
  @media ${bpMedium} {
    height: 92px;
    width: 92px;
    padding: 6px;
  }
`;

const titleCss = css`
  font-family: ${captionTypeface};
  font-size: 14px;
  line-height: 14px;
  border-bottom: 1px solid;
  padding-bottom: 2px;
  display: inline-block;
  margin-top: 12px;
  @media ${bpMedium} {
    font-size: 16px;
    line-height: 16px;
  }
`;

export default function Portrait({
  tint = "gray",
  image,
  name,
  ...props
}: Props) {
  const borderColor = tint === "gold" ? colorGoldStroke : colorGrayStroke;
  return (
    <figure css={{ margin: 0, textAlign: "center" }} {...props}>
      <div css={[outlineStyle, { borderColor }]} className="outline">
        {image != null && (
          <Image
            css={imageStyle}
            fluid={image as FluidObject}
            alt={name ?? "Porträttbild"}
            sizes={image as FluidObject}
            className="image"
          />
        )}
      </div>
      {name && (
        <figcaption css={[titleCss, { borderColor }]}>{name}</figcaption>
      )}
    </figure>
  );
}
