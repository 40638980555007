import { css } from "@emotion/core";
import React, { ComponentPropsWithoutRef } from "react";
import { Link } from "gatsby";
import { bpSmall, bpLarge } from "../../stylingConstants";
import Portrait from "../Atoms/Portrait";
import { ContentfulFluid } from "../../../graphql-schema-types";

type Props = {
  maxNumberOfColumns?: 2 | 3 | 4 | 5;
  people: {
    tint?: "gold" | "gray";
    image: Partial<ContentfulFluid>;
    name?: string | null;
    href?: string | null;
  }[];
};

export default function PortraitGrid({
  people,
  maxNumberOfColumns = 3,
  ...props
}: Props) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      `}
      {...props}
    >
      {people.map(({ tint = "gray", ...person }) => {
        const WrapperComponent = (props: ComponentPropsWithoutRef<"div">) =>
          person.href ? (
            //@ts-ignore: It's fine
            <Link
              to={person.href}
              {...props}
              css={css`
                .outline,
                .image {
                  transition: transform 250ms;
                  transition-timing-function: ease-out;
                }
                :hover {
                  .outline {
                    transform: scale(1.03);
                    transition-timing-function: ease-in;
                  }
                  .image {
                    transform: scale(0.97);
                    transition-timing-function: ease-in;
                  }
                }
              `}
            />
          ) : (
            <div {...props} />
          );
        return (
          <WrapperComponent
            key={`${person.name}${person?.image?.src}`}
            css={css`
              width: 50%;
              margin-bottom: 28px;
              ${maxNumberOfColumns >= 3 &&
              css`
                @media ${bpSmall} {
                  width: ${100 / 3}%;
                }
              `}
              ${maxNumberOfColumns >= 4 &&
              css`
                @media ${bpLarge} {
                  width: ${100 / maxNumberOfColumns}%;
                }
              `}
            `}
          >
            <Portrait image={person.image} tint={tint} name={person.name} />
          </WrapperComponent>
        );
      })}
    </div>
  );
}
